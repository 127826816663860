import { __decorate } from "tslib";
import { indicatorChartReferencesModule } from "@/store/modules/indicator-chart-references";
import { indicatorReferencesModule } from "@/store/modules/indicator-references";
import { unitReferencesModule } from "@/store/modules/unit-references";
import { Component, Vue } from "vue-property-decorator";
let AppRefNamer = class AppRefNamer extends Vue {
    getChartName(chartId) {
        return indicatorChartReferencesModule.indicatorChartReferences?.find((ref) => ref.id === chartId)?.name;
    }
    getLabelName(chartId, labelId) {
        return indicatorChartReferencesModule.indicatorChartReferences
            ?.find((ref) => ref.id === chartId)
            ?.labels?.find((l) => l.id === labelId)?.name;
    }
    getIndicatorName(indicatorId) {
        return indicatorReferencesModule.indicatorReferences?.find((ref) => ref.id === indicatorId)?.name;
    }
    getUnitName(unitId) {
        return unitReferencesModule.unitReferences?.find((ref) => ref.id === unitId)
            ?.name;
    }
};
AppRefNamer = __decorate([
    Component
], AppRefNamer);
export default AppRefNamer;
