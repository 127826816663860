import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
let AppPlaceholder = class AppPlaceholder extends Vue {
};
__decorate([
    Prop({ default: "100%" })
], AppPlaceholder.prototype, "width", void 0);
__decorate([
    Prop({ default: "400px" })
], AppPlaceholder.prototype, "height", void 0);
__decorate([
    Prop({ default: "table_rows" })
], AppPlaceholder.prototype, "icon", void 0);
AppPlaceholder = __decorate([
    Component
], AppPlaceholder);
export default AppPlaceholder;
