import { Fetch } from "./fetch";
const url = "indicator-charts";
export default {
    load(year, indicatorId, unitId) {
        return Fetch.post(url, {
            year,
            indicatorId,
            unitId,
        });
    },
    loadGroup(year, indicatorId, group) {
        return Fetch.post(`${url}/group`, {
            year,
            indicatorId,
            group,
        });
    },
    upsert(body) {
        return Fetch.put(url, body);
    },
    delete(id) {
        return Fetch.delete(`${url}/${id}`);
    },
    getGroupAverage(body) {
        return Fetch.post(`${url}/group-average`, body);
    },
};
