import { __decorate } from "tslib";
import indicatorCharts from "@/api/indicatorCharts";
import { indicatorChartReferencesModule } from "@/store/modules/indicator-chart-references";
import { unitReferencesModule } from "@/store/modules/unit-references";
import { Bar } from "vue-chartjs";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import AppRefNamer from "./shared/AppRefNamer";
import { formatLabel } from "./shared/formatLabel";
import AppBtn from "./shared/ui/AppBtn.vue";
import AppPlaceholder from "./shared/ui/AppPlaceholder.vue";
let AppBarChart = class AppBarChart extends mixins(AppRefNamer) {
    constructor() {
        super(...arguments);
        this.groupAverage = null;
        this.colors = [
            "#4a148c",
            "#006064",
            "#1b5e20",
            "#880e4f",
            "#1a237e",
            "#0d47a1",
            "#ff6f00",
            "#e65100",
        ];
        this.chartOptions = {
            responsive: true,
            aspectRatio: 3,
            plugins: {
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: {
                        callback: function (value) {
                            let label = this.getLabelForValue(value);
                            return formatLabel(label, 15);
                        },
                    },
                },
            },
        };
    }
    get chartName() {
        return this.getChartName(this.indicatorChart.chartId);
    }
    get indicatorChartReference() {
        return indicatorChartReferencesModule.indicatorChartReferences?.find((i) => i.id === this.indicatorChart.chartId);
    }
    get group() {
        return (this.forcedGroup ??
            unitReferencesModule.unitReferences?.find((u) => u.id === this.indicatorChart.unitId)?.group);
    }
    get chartData() {
        if (!this.indicatorChartReference)
            return null;
        if (this.allowShowGroupAverage &&
            this.indicatorChartReference.showGroupAverage &&
            !this.groupAverage)
            return null;
        const entries = this.indicatorChartReference?.labels?.map((l) => ({
            labelId: l.id,
            value: this.indicatorChart.entries?.find((e) => e.labelId === l.id)?.value ??
                null,
        }));
        const labels = entries?.map((e) => this.getLabelName(this.indicatorChart.chartId, e.labelId));
        const values = entries?.map((e) => e.value);
        if (this.indicatorChartReference.showTotal) {
            labels.push("Total");
            values.push(entries?.reduce((acc, curr) => acc + curr.value, 0) ?? 0);
        }
        if (this.indicatorChartReference.showGroupAverage && this.group) {
            const showGroupAverageLabel = this.indicatorChartReference.showGroupAverage === "total"
                ? "Total"
                : this.getLabelName(this.indicatorChartReference.id, this.indicatorChartReference.showGroupAverage);
            const levelLabel = this.groupAverageLevel === "company" ? "compagnie" : "unité";
            labels.push(`Moyenne par ${levelLabel} du ${this.group} pour ${showGroupAverageLabel}`);
            values.push(this.groupAverage);
        }
        return {
            labels,
            datasets: [
                {
                    data: values,
                    backgroundColor: values.map((v, i) => this.colors[i]),
                },
            ],
        };
    }
    onindicatorChartReferenceChange() {
        if (this.indicatorChartReference)
            this.loadGroupAverage();
    }
    async loadGroupAverage() {
        if (this.indicatorChartReference.showGroupAverage) {
            if (!this.group)
                return;
            this.groupAverage = await indicatorCharts
                .getGroupAverage({
                group: this.group,
                year: this.indicatorChart.year,
                chartId: this.indicatorChart.chartId,
                indicatorId: this.indicatorChart.indicatorId,
                showGroupAverage: this.indicatorChartReference.showGroupAverage,
                level: this.groupAverageLevel,
            })
                .then((data) => data.groupAverage);
        }
    }
};
__decorate([
    Prop()
], AppBarChart.prototype, "indicatorChart", void 0);
__decorate([
    Prop({ default: false })
], AppBarChart.prototype, "showEdit", void 0);
__decorate([
    Prop({ default: "unit" })
], AppBarChart.prototype, "groupAverageLevel", void 0);
__decorate([
    Prop({ required: false })
], AppBarChart.prototype, "forcedGroup", void 0);
__decorate([
    Prop({ default: true })
], AppBarChart.prototype, "allowShowGroupAverage", void 0);
__decorate([
    Watch("indicatorChartReference", { immediate: true })
], AppBarChart.prototype, "onindicatorChartReferenceChange", null);
AppBarChart = __decorate([
    Component({
        components: { Bar, AppBtn, AppPlaceholder },
    })
], AppBarChart);
export default AppBarChart;
