import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
let AppTextPlaceholder = class AppTextPlaceholder extends Vue {
};
__decorate([
    Prop({ default: "Rien à afficher." })
], AppTextPlaceholder.prototype, "text", void 0);
__decorate([
    Prop({ default: "200px" })
], AppTextPlaceholder.prototype, "height", void 0);
AppTextPlaceholder = __decorate([
    Component
], AppTextPlaceholder);
export default AppTextPlaceholder;
