import { render, staticRenderFns } from "./AppModal.vue?vue&type=template&id=965f7a62&scoped=true&"
import script from "./AppModal.vue?vue&type=script&lang=ts&"
export * from "./AppModal.vue?vue&type=script&lang=ts&"
import style0 from "./AppModal.vue?vue&type=style&index=0&id=965f7a62&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "965f7a62",
  null
  
)

export default component.exports