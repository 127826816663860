import { render, staticRenderFns } from "./AppBarChart.vue?vue&type=template&id=add587cc&scoped=true&"
import script from "./AppBarChart.vue?vue&type=script&lang=ts&"
export * from "./AppBarChart.vue?vue&type=script&lang=ts&"
import style0 from "./AppBarChart.vue?vue&type=style&index=0&id=add587cc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "add587cc",
  null
  
)

export default component.exports