import { __decorate } from "tslib";
import { Modal } from "@materializecss/materialize";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
let AppModal = class AppModal extends Vue {
    constructor() {
        super(...arguments);
        this.instance = null;
        this.primaryClicked = false;
    }
    get isDeleteType() {
        return this.type === "delete";
    }
    openModal() {
        this.instance?.destroy?.();
        this.instance = Modal.init(this.$refs.appModal, {
            onCloseEnd: async () => {
                if (!this.primaryClicked)
                    this.$emit("confirmed", false);
                this.endModal();
            },
            dismissible: this.dismissible,
            ...this.options,
        });
        this.instance.open();
    }
    onPrimaryClick() {
        this.primaryClicked = true;
        this.$emit("confirmed", true);
        if (this.closeOnPrimaryClick)
            this.closeModal();
    }
    beforeDestroy() {
        this.closeModal();
    }
    closeModal() {
        this.instance?.close();
    }
    endModal() {
        this.instance?.destroy();
        this.instance = null;
        this.$emit("input", false);
    }
    onValueChanged(value) {
        if (value && !this.instance)
            this.openModal();
        else if (!value && this.instance)
            this.closeModal();
    }
};
__decorate([
    Prop({ required: true })
], AppModal.prototype, "id", void 0);
__decorate([
    Prop({ default: "Merci de confirmer." })
], AppModal.prototype, "title", void 0);
__decorate([
    Prop({ default: "confirm" })
], AppModal.prototype, "type", void 0);
__decorate([
    Prop({ default: "Confirmer" })
], AppModal.prototype, "primaryText", void 0);
__decorate([
    Prop({ default: false })
], AppModal.prototype, "primaryDisabled", void 0);
__decorate([
    Prop({ default: "Annuler" })
], AppModal.prototype, "secondaryText", void 0);
__decorate([
    Prop({ default: false })
], AppModal.prototype, "secondaryDisabled", void 0);
__decorate([
    Prop({ default: true })
], AppModal.prototype, "dismissible", void 0);
__decorate([
    Prop({ default: true })
], AppModal.prototype, "closeOnPrimaryClick", void 0);
__decorate([
    Prop({ default: false })
], AppModal.prototype, "value", void 0);
__decorate([
    Prop({ default: () => ({}) })
], AppModal.prototype, "options", void 0);
__decorate([
    Watch("value")
], AppModal.prototype, "onValueChanged", null);
AppModal = __decorate([
    Component
], AppModal);
export default AppModal;
